import { ROLES, USER_LOCATIONS, VIRTUAL_PT_PROGRAM_TYPE } from '@/scripts/constants';

function hasRole(role, user) {
  return role && user && (
    user.role === role || !!(user.roles || []).find(({ name }) => name === role)
  );
}

export default {
  data() {
    return {
      allowedUserLocations: [
        USER_LOCATIONS.CANELAS,
        USER_LOCATIONS.SALT_LAKE,
      ],
    };
  },
  methods: {
    isAdmin(user) {
      return hasRole(ROLES.admin, user);
    },
    isProfessional(user) {
      return hasRole(ROLES.professional, user);
    },
    isManager(user) {
      return hasRole(ROLES.manager, user);
    },
    isFinance(user) {
      return hasRole(ROLES.finance, user);
    },
    isProduction(user) {
      return hasRole(ROLES.production, user);
    },
    isCareCoordinator(user) {
      return hasRole(ROLES.care_coordinator, user);
    },
    isProgramTypeVirtualPT(user) {
      return user?.program_type === VIRTUAL_PT_PROGRAM_TYPE;
    },
  },
};
