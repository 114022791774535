<template>
  <b-container class="p-4 orders-list-holder orders-colors">
    <b-row class="mb-2">
      <b-col>
        <h3>Orders</h3>
      </b-col>
      <b-col cols="9">
        <order-filter
          v-for="(status, statusKey) in statuses" :key="statusKey"
          :status="statusKey"
          :statusName="status.name"
          :counter="status.counter"
          :selected="quickFilterIsset(statusKey)"
          @filter-activated="setStatusFilter(statusKey)"
          :enabled="canFilter()">
        </order-filter>
      </b-col>
      <b-col class="buttons-holder" v-show=canExport()>
        <b-button variant="success" @click="exportOrders" :disabled='exportDisabled'>Export</b-button>
        <div id="info-export-orders">
            <b-icon icon="info-circle" scale="1.5"></b-icon>
          </div>
          <b-tooltip
            id="info-export-orders-tooltip"
            target="info-export-orders"
            triggers="hover"
            placement="top">
            Exportable orders list should be less than <strong> {{ this.limitExportableOrders }}</strong>.<br>
            Filtered Orders: <strong :class="searchCanBeExported ? 'exportable': 'not-exportable'"> {{ this.countOrders }} </strong><br>
            <strong v-show="!searchCanBeExported"> Please, refine your search</strong>
          </b-tooltip>
      </b-col>
    </b-row>
    <div class="filters_holder">
      <h6 v-b-toggle.collapse-2 class="m-1">Filters</h6>
      <b-collapse id="collapse-2">
        <b-row class="d-flex mb-4">
          <b-col cols="2">
            <b-form-group label="Order ID">
              <b-form-input
                    v-model="filters.data.order_id"
                                  type="number"
                                  min=1
                                  oninput="javascript:if(this.value.length>10) this.value=this.value.slice(0,10);">
              </b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="2">
            <b-form-group label="Patient ID">
              <b-form-input
                v-model="filters.data.patient_id"
                type="number"
                min=1
                oninput="javascript:if(this.value.length>10) this.value=this.value.slice(0,10);">
              </b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="2">
            <b-form-group label="SKU">
              <b-form-input
                v-model="filters.data.sku"
                type="text"
                maxLength=254>
              </b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="3" class="colTime">
            <b-form-group label="Created from: (UTC time)">
              <VueCtkDateTimePicker format="YYYY-MM-DD HH:mm:ss"
                                    left
                                    label="from..."
                                    :range="false"
                                    :no-button-now="true"
                                    :only-date="false"
                                    :min-date="minDateAvailable"
                                    :no-clear-button="true"
                                    v-model="filters.data.date_start"/>
            </b-form-group>
          </b-col>
          <b-col cols="3" class="colTime">
            <b-form-group label="Created to: (UTC time)">
              <VueCtkDateTimePicker format="YYYY-MM-DD HH:mm:ss"
                                    left
                                    label="to..."
                                    :range="false"
                                    :no-button-now="true"
                                    :only-date="false"
                                    :min-date="minDateAvailable"
                                    :no-clear-button="true"
                                    v-model="filters.data.date_end"/>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="d-flex mb-4">
          <b-col cols="2">
            <b-form-group
              id="input-group-method"
              label="Method"
              class="exception-label clients-exceptions-group">
                <multiselect
                    v-model="filters.data.orderMethods"
                    tag-placeholder=""
                    placeholder=""
                    :options="methodOptions"
                    track-by="code"
                    label="label"
                    class="exception-multiselect"
                    :multiple="true"
                    :taggable="false"></multiselect>
            </b-form-group>
          </b-col>

          <b-col cols="2">
            <b-form-group
              id="input-group-status"
              label="Status"
              class="exception-label clients-exceptions-group">
              <multiselect
                v-model="filters.data.status"
                tag-placeholder=""
                placeholder=""
                :options="statusOptions"
                track-by="code"
                label="label"
                class="exception-multiselect"
                :limit="3"
                :multiple="true"
                :taggable="false"></multiselect>
            </b-form-group>
          </b-col>

          <b-col cols="2">
            <b-form-group
                id="input-group-product-type"
                label="Product type"
                class="exception-label clients-exceptions-group">
              <multiselect
                  v-model="filters.data.productType"
                  tag-placeholder=""
                  placeholder=""
                  :options="productTypeOptions"
                  track-by="value"
                  label="text"
                  class="exception-multiselect"
                  :limit="3"
                  :multiple="true"
                  :taggable="false"></multiselect>
            </b-form-group>
          </b-col>

          <b-col cols="3" class="colTime">
            <b-form-group label="Updated from: (UTC time)">
              <VueCtkDateTimePicker format="YYYY-MM-DD HH:mm:ss"
                                    left
                                    label="from..."
                                    :range="false"
                                    :no-button-now="true"
                                    :only-date="false"
                                    :min-date="minDateAvailable"
                                    :no-clear-button="true"
                                    v-model="filters.data.updated_at_start"/>
            </b-form-group>
          </b-col>
          <b-col cols="3" class="colTime">
            <b-form-group label="Updated to: (UTC time)">
              <VueCtkDateTimePicker format="YYYY-MM-DD HH:mm:ss"
                                    left
                                    label="to..."
                                    :range="false"
                                    :no-button-now="true"
                                    :only-date="false"
                                    :min-date="minDateAvailable"
                                    :no-clear-button="true"
                                    v-model="filters.data.updated_at_end"/>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="d-flex mb-4">
          <b-col cols="2">
            <b-form-group
              id="input-group-component-type"
              label="Component Type"
              class="exception-label clients-exceptions-group">
              <multiselect
                v-model="filters.data.componentTypes"
                tag-placeholder=""
                placeholder=""
                :options="componentTypeOptions"
                track-by="code"
                label="label"
                class="exception-multiselect"
                :multiple="true"
                :taggable="false"></multiselect>
            </b-form-group>
          </b-col>

          <b-col cols="2">
            <b-form-group
              id="input-group-ship-via"
              label="Ship via"
              class="exception-label clients-exceptions-group">
              <b-form-input
                  v-model="filters.data.shipVia"
                  type="text"
                  maxLength=254>
              </b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="4">
            <b-form-group
                id="input-group-tags"
                label="Order Tags"
                class="exception-label clients-exceptions-group">
              <multiselect
                  v-model="filters.data.orderTags"
                  tag-placeholder=""
                  placeholder=""
                  :options="orderTagsOptions"
                  track-by="value"
                  label="text"
                  class="exception-multiselect"
                  :limit="3"
                  :multiple="true"
                  :taggable="false"></multiselect>
            </b-form-group>
          </b-col>

          <b-col cols="4">
            <b-form-group
                id="input-group-clientName"
                label="Client name"
                class="exception-label clients-exceptions-group">
              <multiselect
                  v-model="filters.data.client_names"
                  tag-placeholder=""
                  placeholder=""
                  :options="clientOptions"
                  track-by="code"
                  label="name"
                  class="exception-multiselect"
                  :multiple="true"
                  :limit="3"
                  :taggable="false"></multiselect>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="d-flex mb-4">
          <b-col cols="3">
            <b-form-group label="Account UUID">
              <b-form-input
                v-model="filters.data.account_uuid"
                type="text"
                maxLength=254>
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="d-flex mb-4">
          <b-col cols="4">
            <b-form-group label="Archived" v-slot="{ ariaDescribedby }">
              <b-form-radio-group
                v-model="filters.data.archived"
                :options="archivedOptions"
                :aria-describedby="ariaDescribedby"
                name="radio-inline">
              </b-form-radio-group>
            </b-form-group>
          </b-col>

          <b-col cols="2" class="d-flex align-items-center">
            <b-form-group>
              <b-form-checkbox-group
                v-model="filters.data.event_type"
                id="event_type"
                :options="eventTypes">
              </b-form-checkbox-group>
            </b-form-group>
          </b-col>
          <b-col cols="3" class="d-flex align-items-center">
            <b-form-group>
              <b-form-checkbox
                v-model="filters.data.include_test_orders"
                id="include_test_orders"
                name="include_test_orders">
                Include test orders
              </b-form-checkbox>
            </b-form-group>
          </b-col>

          <b-col cols="3" class="col_buttons">
              <b-button variant="light" class="mr-3 float-right" @click="resetFilters">Reset</b-button>
              <b-button variant="primary" class="mr-3 float-right" @click="applyFilters" :disabled="!canFilter()">Apply</b-button>
          </b-col>
        </b-row>
      </b-collapse>
    </div>
    <Orders ref="orders" :filters="filters" @ordersLoaded="searchCanBeExported"></Orders>
  </b-container>
</template>

<script>
import translations from '@/translations';
import {
  ORDER_TYPE, QUERY_OPERATORS, ORDER_STATUS, ORDER_TAGS, QUERY_FIELD,
} from '@/scripts/constants';
import moment from 'moment';
import axios from 'axios';
import Multiselect from 'vue-multiselect';
import userMixin from '@/mixins/user';
import orderHandler from '@/mixins/orderHandler';
import Orders from '../../../components/OrderManagement/Orders/OrdersList.vue';
import OrderFilter from '../../../components/OrderManagement/Orders/OrderFilter.vue';

const orderStatusNew = { code: 'new', label: translations.orders.status.new };
const orderStatusInvalid = { code: 'invalid', label: translations.orders.status.invalid };
const orderStatusFailed = { code: 'failed', label: translations.orders.status.failed };
const orderStatusCanceled = { code: 'canceled', label: translations.orders.status.canceled };
const orderStatusDelayed = { code: 'delayed', label: translations.orders.status.delayed };
const orderStatusOutOfStock = { code: 'out_of_stock', label: translations.orders.status.out_of_stock };
const orderStatusFailedDelivery = { code: 'failed_delivery', label: translations.orders.status.failed_delivery };
const orderStatusReturnedToSender = { code: 'returned_to_sender', label: translations.orders.status.returned_to_sender };
const orderStatusAvailableForPickup = { code: 'available_for_pickup', label: translations.orders.status.available_for_pickup };
const orderStatusProductionInfo = { code: 'production_info', label: translations.orders.tags.production_info };
const orderStatusDistributionInfo = { code: 'distribution_info', label: translations.orders.tags.distribution_info };

const EVENT_TYPES = {
  EARLY_ENROLLED: 'onboarding.early_enrolled',
};

export default {
  name: 'orders-filters',
  components: {
    Multiselect,
    Orders,
    OrderFilter,
  },
  mixins: [ userMixin, orderHandler ],
  async created() {
    this.limitExportableOrders = 200000;
    this.statusOptions = [
      orderStatusNew,
      orderStatusInvalid,
      { code: 'pending_order_retry', label: translations.orders.status.pending_order_retry },
      orderStatusFailed,
      orderStatusCanceled,
      { code: 'created', label: translations.orders.status.created },
      { code: 'shipped', label: translations.orders.status.shipped },
      orderStatusAvailableForPickup,
      { code: 'delivered', label: translations.orders.status.delivered },
      orderStatusDelayed,
      orderStatusOutOfStock,
      orderStatusFailedDelivery,
      orderStatusReturnedToSender,
      { code: 'returned', label: translations.orders.status.returned },
    ];
    this.methodOptions = [
      { code: 'manual', label: translations.orders.method.manual },
      { code: 'auto', label: translations.orders.method.auto },
    ];
    this.componentTypeOptions = [
      { code: 'kit', label: translations.orders.component_type.kit },
      { code: 'spare_part', label: translations.orders.component_type.spare_part },
    ];
    await this.loadClients();
    await this.loadProductTypes();
    await this.loadOrderTags();
    this.$watch(
      () => this.$route.query,
      () => {
        this.$refs.orders.loadOrdersList(true);
      },
    );
  },
  async mounted() {
    await this.getOrdersOverview();
  },
  data() {
    return {
      componentTypeOptions: [],
      productTypeOptions: [],
      orderTagsOptions: [],
      statusOptions: [],
      methodOptions: [],
      shipViasOptions: [],
      clientOptions: [],
      exportDisabled: true,
      countOrders: 0,
      selectedEventTypes: [],
      eventTypes: [
        { checked: false, value: EVENT_TYPES.EARLY_ENROLLED, text: 'Early kit' },
      ],
      filters: {
        isValid: false,
        data: {
          date_start: this.$route.query.date_start,
          date_end: this.$route.query.date_end,
          updated_at_start: this.$route.query.updated_at_start,
          updated_at_end: this.$route.query.updated_at_end,
          order_id: this.$route.query.order_id,
          sku: this.$route.query.sku,
          client_names: this.$route.query.client_names ? JSON.parse(this.$route.query.client_names) : [],
          orderMethods: this.$route.query.orderMethods ? JSON.parse(this.$route.query.orderMethods) : [],
          productType: this.$route.query.productType ? JSON.parse(this.$route.query.productType) : [],
          shipVia: this.$route.query.shipVia,
          patient_id: this.$route.query.patient_id,
          status: this.$route.query.status ? JSON.parse(this.$route.query.status) : [],
          orderTags: this.$route.query.orderTags ? JSON.parse(this.$route.query.orderTags) : [],
          tags: this.$route.query.tags ? JSON.parse(this.$route.query.tags) : [],
          archived: this.$route.query.archived ? this.$route.query.archived : QUERY_OPERATORS.notEqual,
          componentTypes: this.$route.query.componentTypes ? JSON.parse(this.$route.query.componentTypes) : [],
          account_uuid: this.$route.query.account_uuid,
          event_type: this.$route.query.event_type ? JSON.parse(this.$route.query.event_type) : [],
          include_test_orders: this.$route.query.include_test_orders ? this.$route.query.include_test_orders === 'true' : true,
        },
      },
      statuses: {
        new: {
          counter: 0,
          name: translations.orders.status.new,
          selected: false,
        },
        available_for_pickup: {
          counter: 0,
          name: translations.orders.status.available_for_pickup,
          selected: false,
        },
        failed: {
          counter: 0,
          name: translations.orders.status.failed,
          selected: false,
        },
        invalid: {
          counter: 0,
          name: translations.orders.status.invalid,
          selected: false,
        },
        canceled: {
          counter: 0,
          name: translations.orders.status.canceled,
          selected: false,
        },
        delayed: {
          counter: 0,
          name: translations.orders.status.delayed,
          selected: false,
        },
        out_of_stock: {
          counter: 0,
          name: translations.orders.status.out_of_stock,
          selected: false,
        },
        failed_delivery: {
          counter: 0,
          name: translations.orders.status.failed_delivery,
          selected: false,
        },
        returned_to_sender: {
          counter: 0,
          name: translations.orders.status.returned_to_sender,
          selected: false,
        },
        production_info: {
          counter: 0,
          name: translations.orders.tags.production_info,
          selected: false,
        },
        distribution_info: {
          counter: 0,
          name: translations.orders.tags.distribution_info,
          selected: false,
        },
      },
      isHovered: false,
      archivedOptions: [
        { value: '', text: 'See all' },
        { value: QUERY_OPERATORS.equal, text: 'See only archived' },
        { value: QUERY_OPERATORS.notEqual, text: 'Exclude archived' },
      ],
    };
  },
  computed: {
    minDateAvailable() {
      return moment().subtract(1, 'year').format('YYYY-MM-DD HH:mm:ss');
    },
    validateDateFilter() {
      const { data } = this.filters;
      return (data.date_start && moment(data.date_start).isValid())
              || (data.date_end && moment(data.date_end).isValid());
    },
    validateUpdateAtFilter() {
      const { data } = this.filters;
      return (data.updated_at_start && moment(data.updated_at_start).isValid())
          || (data.updated_at_end && moment(data.updated_at_end).isValid());
    },
  },
  methods: {
    handleInfoHover(hovered) {
      this.isHovered = hovered;
    },
    canFilter() {
      if (this.validateDateFilter || this.filters.data.order_id || this.filters.data.patient_id
          || this.filters.data.status || this.filters.data.sku || this.filters.data.orderMethods
          || this.filters.data.client_names || this.filters.data.productType || this.filters.data.shipVia
          || this.validateUpdateAtFilter || this.filters.data.archived || this.filters.data.account_uuid
          || this.filters.data.eventTypes) {
        this.filters.isValid = true;
      } else this.filters.isValid = true;

      return this.filters.isValid;
    },
    applyFilters() {
      const { data } = this.filters;
      this.getOrdersOverview();
      this.$router.push({
        name: 'Orders',
        query: {
          order_id: data.order_id,
          date_start: data.date_start,
          date_end: data.date_end,
          updated_at_start: data.updated_at_start,
          updated_at_end: data.updated_at_end,
          sku: data.sku,
          client_names: JSON.stringify(data.client_names),
          orderMethods: JSON.stringify(data.orderMethods),
          productType: JSON.stringify(data.productType),
          shipVia: data.shipVia,
          patient_id: data.patient_id,
          status: JSON.stringify(data.status),
          orderTags: JSON.stringify(data.orderTags),
          tags: JSON.stringify(data.tags),
          archived: data.archived,
          componentTypes: JSON.stringify(data.componentTypes),
          account_uuid: data.account_uuid,
          event_type: JSON.stringify(data.event_type),
          include_test_orders: data.include_test_orders,
        },
      });
    },
    resetFilters() {
      this.clearFilters();
      this.getOrdersOverview();
      this.$refs.orders.loadOrdersList(true);
    },
    clearFilters() {
      Object.assign(this.$data.filters, {
        isValid: false,
        data: {
          date_start: null,
          date_end: null,
          updated_at_start: null,
          updated_at_end: null,
          order_id: null,
          sku: null,
          client_names: [],
          orderMethods: [],
          productType: [],
          shipVia: null,
          patient_id: null,
          status: [],
          archived: QUERY_OPERATORS.notEqual,
          componentTypes: [],
          orderTags: [],
          tags: [],
          account_uuid: null,
          event_type: [],
          include_test_orders: true,
        },
      });
      // Redirect to Orders component, without query parameters
      this.$router.push({
        name: 'Orders',
      });
    },
    async loadClients() {
      await axios
        .get('v1/clients')
        .then(data => {
          data.data.payload.forEach(p => {
            this.clientOptions.push({ id: p.id, code: p.name, name: p.display_name });
          });
        })
        .catch(response => {
          this.$noty.error('Unable to load Clients');
          console.error(response);
        });
    },
    async loadProductTypes() {
      const productTypes = await this.$store.dispatch('OrderManagement/getProductTypes');
      this.productTypeOptions = productTypes.map(({ productTypeKey, productTypeName }) => ({ text: productTypeName, value: productTypeKey }));
    },
    async loadOrderTags() {
      const tags = await this.$store.dispatch('OrderManagement/getOrdersTags');
      this.orderTagsOptions = tags.map(({ tag }) => ({
        text: translations.orders.tags[tag],
        value: tag,
      }));
    },
    exportOrders() {
      this.$refs.orders.exportOrders();
    },
    buildFilters() {
      this.$refs.orders.buildFilters();
    },
    async getOrdersOverview() {
      const filters = this.$refs.orders.buildFilters();
      // Filter counters only by archived
      const archivedFilter = filters.filter(filter => filter.field === QUERY_FIELD.ORDER_TAG && filter.value === ORDER_TAGS.Archived);
      const statusOverview = await this.$store.dispatch(
        'OrderManagement/getOrdersOverview',
        {
          type: ORDER_TYPE.FORWARD,
          queries: archivedFilter,
        },
      );

      if (statusOverview) {
        statusOverview.forEach(elem => {
          switch (elem.status) {
            case ORDER_STATUS.New:
            case ORDER_STATUS.Invalid:
            case ORDER_STATUS.Failed:
            case ORDER_STATUS.Delayed:
            case ORDER_STATUS.OutOfStock:
            case ORDER_STATUS.FailedDelivery:
            case ORDER_STATUS.AvailableForPickup:
            case ORDER_TAGS.DistributionInfo:
            case ORDER_TAGS.ProductionInfo:
              this.statuses[elem.status].counter = parseInt(elem.total, 10);
              break;
            default:
              break;
          }
        });
      }
    },
    setStatusFilter(status) {
      this.clearFilters();
      this.filters.isValid = true;

      switch (status) {
        case 'new':
          this.filters.data.status.push(orderStatusNew);
          break;
        case 'canceled':
          this.filters.data.status.push(orderStatusCanceled);
          break;
        case 'failed':
          this.filters.data.status.push(orderStatusFailed);
          break;
        case 'invalid':
          this.filters.data.status.push(orderStatusInvalid);
          break;
        case 'delayed':
          this.filters.data.status.push(orderStatusDelayed);
          break;
        case 'out_of_stock':
          this.filters.data.status.push(orderStatusOutOfStock);
          break;
        case 'failed_delivery':
          this.filters.data.status.push(orderStatusFailedDelivery);
          break;
        case 'returned_to_sender':
          this.filters.data.status.push(orderStatusReturnedToSender);
          break;
        case 'production_info':
          this.filters.data.tags.push(orderStatusProductionInfo);
          break;
        case 'distribution_info':
          this.filters.data.tags.push(orderStatusDistributionInfo);
          break;
        case 'available_for_pickup':
          this.filters.data.status.push(orderStatusAvailableForPickup);
          break;
        default:
          break;
      }

      this.applyFilters();
    },
    /**
     * Check if quick filter (i.e. the buttons on top of the list) is set
     * @param {string} filterKey
     * @returns {boolean}
     * @private
     */
    quickFilterIsset(filterKey) {
      if (!this.$route.query.status && !this.$route.query.tags) {
        return false;
      }

      const statusSet = JSON.parse(this.$route.query.status);
      const tagsSet = JSON.parse(this.$route.query.tags);
      // Check if any status is set
      if (!statusSet.length && !tagsSet.length) {
        return false;
      }

      return statusSet.some(st => st.code === filterKey) || tagsSet.some(st => st.code === filterKey);
    },
  },
};
</script>

<style lang="scss">
  @import '../../../styles/OrderManagement.scss';
</style>
