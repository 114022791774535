<template>
  <b-button
    v-if="counter !== 0"
    @click="$emit('filter-activated')"
    class="mr-2"
    :variant="color"
    :disabled="!enabled">
    {{statusName}} ({{ counter }})
  </b-button>
</template>

<script>
import integer from 'vuelidate/lib/validators/integer';

export default {
  name: 'order-filter',
  props: {
    status: {
      type: String,
      default: null,
    },
    variant: {
      type: String,
      default: 'info',
    },
    statusName: {
      type: String,
      default: null,
    },
    counter: {
      type: integer,
      default: 0,
    },
    enabled: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    color() {
      if (!this.selected) {
        return `outline-${this.status}`;
      }
      return this.status;
    },
  },
};
</script>

<style lang="scss">
  @import '../../../styles/OrderManagement.scss';
</style>
